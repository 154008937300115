<template>
  <div id="workstationLedger">
    <el-dialog
      :title="workstationLedgerFormTitle"
      width="680px"
      :visible.sync="workstationLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="workstationLedgerDialogClose"
    >
      <el-form
        ref="workstationLedgerFormRef"
        :model="workstationLedgerForm"
        :rules="workstationLedgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="no">
              <el-input v-model="workstationLedgerForm.no" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="workstationLedgerForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号" prop="spec">
              <el-input v-model="workstationLedgerForm.spec" placeholder="请输入型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂家" prop="factory">
              <el-input v-model="workstationLedgerForm.factory" placeholder="请输入厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="workstationLedgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="workstationLedgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="workstationLedgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="workstationLedgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="no" label="编号" />
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="型号" />
      <el-table-column prop="factory" label="厂家" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="workstationLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addWorkstationLedger,
  deleteWorkstationLedger,
  updateWorkstationLedger,
  selectWorkstationLedgerInfo,
  selectWorkstationLedgerList
} from '@/api/produce/workstationLedger'

export default {
  data () {
    return {
      workstationLedgerDialogVisible: false,
      workstationLedgerFormTitle: '',
      workstationLedgerForm: {
        id: '',
        no: '',
        name: '',
        spec: '',
        factory: '',
        remarks: ''
      },
      workstationLedgerFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      workstationLedgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectWorkstationLedgerList(this.searchForm).then(res => {
      this.workstationLedgerPage = res
    })
  },
  methods: {
    workstationLedgerDialogClose () {
      this.$refs.workstationLedgerFormRef.resetFields()
    },
    workstationLedgerFormSubmit () {
      if (this.workstationLedgerFormTitle === '工位器具台账详情') {
        this.workstationLedgerDialogVisible = false
        return
      }
      this.$refs.workstationLedgerFormRef.validate(async valid => {
        if (valid) {
          if (this.workstationLedgerFormTitle === '新增工位器具台账') {
            this.workstationLedgerForm.id = ''
            await addWorkstationLedger(this.workstationLedgerForm)
          } else if (this.workstationLedgerFormTitle === '修改工位器具台账') {
            await updateWorkstationLedger(this.workstationLedgerForm)
          }
          this.workstationLedgerPage = await selectWorkstationLedgerList(this.searchForm)
          this.workstationLedgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.workstationLedgerFormTitle = '新增工位器具台账'
      this.workstationLedgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteWorkstationLedger(row.id)
        if (this.workstationLedgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.workstationLedgerPage = await selectWorkstationLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.workstationLedgerFormTitle = '修改工位器具台账'
      this.workstationLedgerDialogVisible = true
      this.selectWorkstationLedgerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.workstationLedgerFormTitle = '工位器具台账详情'
      this.workstationLedgerDialogVisible = true
      this.selectWorkstationLedgerInfoById(row.id)
    },
    selectWorkstationLedgerInfoById (id) {
      selectWorkstationLedgerInfo(id).then(res => {
        this.workstationLedgerForm.id = res.id
        this.workstationLedgerForm.no = res.no
        this.workstationLedgerForm.name = res.name
        this.workstationLedgerForm.spec = res.spec
        this.workstationLedgerForm.factory = res.factory
        this.workstationLedgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectWorkstationLedgerList(this.searchForm).then(res => {
        this.workstationLedgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectWorkstationLedgerList(this.searchForm).then(res => {
        this.workstationLedgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectWorkstationLedgerList(this.searchForm).then(res => {
        this.workstationLedgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
