import axios from '@/common/axios'
import qs from 'qs'

export function addWorkstationLedger (data) {
  return axios({
    method: 'post',
    url: '/production/workstationLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteWorkstationLedger (id) {
  return axios({
    method: 'delete',
    url: '/production/workstationLedger/delete/' + id
  })
}

export function updateWorkstationLedger (data) {
  return axios({
    method: 'put',
    url: '/production/workstationLedger/update',
    data: qs.stringify(data)
  })
}

export function selectWorkstationLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/production/workstationLedger/info/' + id
  })
}

export function selectWorkstationLedgerList (query) {
  return axios({
    method: 'get',
    url: '/production/workstationLedger/list',
    params: query
  })
}
